import React, { createContext, useState, FC, useEffect, useMemo } from "react";
import "scss/components/_toast.scss";
import {
  close as closeIcon,
  info,
  checkCircle,
  error,
  warning,
} from "script/icons";
import Loader from "views/widgets/loader";

type ToastTypes = "primary" | "success" | "danger" | "warning" | "loader";

type ToastElement = {
  id?: number;
  title?: string;
  description: string;
  type?: ToastTypes;
  children?: React.ReactNode;
};

const className = "ltb-toaster";
const toastDuration = 5000;
type toastDefaultIconsTypes = { [keys in ToastTypes]: JSX.Element };
const toastDefaultIcons: toastDefaultIconsTypes = {
  primary: info,
  success: checkCircle,
  danger: error,
  warning: warning,
  loader: <Loader></Loader>,
};

export type ToastContextState = {
  pushToast: (toast: ToastElement) => void;
  removeToast: (id: number) => void;
};

export interface ToasterProps {
  children: React.ReactNode;
}

const contextDefaultValues: ToastContextState = {
  pushToast: () => {},
  removeToast: () => {},
};

export const ToasterContext =
  createContext<ToastContextState>(contextDefaultValues);

const ToasterProvider: FC = ({ children }) => {
  const [toasts, setToast] = useState<ToastElement[]>([]);

  useEffect(() => {
    var interval: NodeJS.Timer | any ;
    interval = setInterval(() => {
      toasts.forEach((el) => {
        if (el.type !== "loader" && Date.now() - (el.id || 0) > toastDuration)
          removeToast(el.id);
      });
      if (toasts.length === toasts.filter((el) => el.type === "loader").length)
        clearInterval(interval);
    }, 100);
    return () => {
      clearInterval(interval);
    };
  }, [toasts]);

  const removeToast = (id?: number) => {
    const currentToasts = [...toasts];
    const listItemIndex = currentToasts.findIndex((e) => e.id === id);
    currentToasts.splice(listItemIndex, 1);
    setToast(() => [...currentToasts]);
  };

  const pushToast = (toast: ToastElement) => {
    toast.id = Date.now();
    setToast((toasts) => [...toasts, { ...toast }]);
    return toast.id;
  };

  const toastIconBuild = (toast: ToastElement) => {
    return (
      <div className={`${className}__toast__icon`}>
        {toast.children ?? toastDefaultIcons[toast.type || "primary"]}
      </div>
    );
  };

  const toastTextContentBuild = (description: string, title?: string) => {
    return (
      <div className={`${className}__toast__text`}>
        {!!title?.length ? (
          <div className={`${className}__toast__text__title`}>{title}</div>
        ) : (
          <></>
        )}

        <div className={`${className}__toast__text__description`}>
          {description}
        </div>
      </div>
    );
  };

  const toastCLoseIconBuild = (id: number) => {
    return (
      <div
        className={`${className}__toast__close`}
        onClick={() => removeToast(id)}
      >
        {closeIcon}
      </div>
    );
  };

  const toastBuild = () => {
    return (
      <div className={className}>
        {toasts.map((toast) => (
          <div
            className={[
              `${className}__toast`,
              `${className}__toast__${toast.type}`,
            ].join(" ")}
            key={toast.id}
          >
            {toastIconBuild(toast)}
            {toastTextContentBuild(toast.description, toast.title)}
            {toast.type === "loader" ? (
              <></>
            ) : (
              toastCLoseIconBuild(toast.id || 0)
            )}
          </div>
        ))}
      </div>
    );
  };

  const value = useMemo(() => ({ pushToast, removeToast }), []);

  return (
    <ToasterContext.Provider value={value}>
      {children}
      {toasts.length ? toastBuild() : <></>}
    </ToasterContext.Provider>
  );
};

export default ToasterProvider;
