import { authContext } from "auth/auth.provider";
import NavigationLayout from "containers/NavigationLayout";
import { Suspense, useContext } from "react";
import { Redirect, Route, RouteComponentProps } from "react-router-dom";
import LTBLoader from "views/widgets/LTBLoader";

const PrivateRoute = ({
  component: Component,
  path,
  name,
  exact,
  isProtected,
  withNavigation,
  props,
  ...rest
}: IRoute) => {
  const { isAuthenticated } = useContext(authContext);

  const Page = () => {
    return (
      <Route
        {...props}
        path={path}
        exact={exact}
        render={(props: RouteComponentProps<any>) =>
          isAuthenticated ? (
            <Component name={name} {...props} {...rest} />
          ) : (
            <Redirect to="/login" />
          )
        }
      />
    );
  };
  return (
    <>
      {withNavigation ? (
        <NavigationLayout>
          <Suspense fallback={<LTBLoader />}>
            <Page></Page>
          </Suspense>
        </NavigationLayout>
      ) : (
        <Page></Page>
      )}
    </>
  );
};

export default PrivateRoute;
